<template>
	<div class="pcpage">
		<div class="pchade animate__animated animate__fadeInDown">
			<div class="pchanek">
				<img class="pclogo" src="../../assets/img/logo1.png"/>
			</div>
		</div>
		<div class="pcbannerk animate__animated animate__fadeInLeft">
			<img src="../../assets/img/bf.png" class="pcbf" title="播放"  @click='checkVideoFun()'/>
		</div>
		<div v-if="videoState == true" class="videomasks">
			<img src="../../assets/img/gbsp.png" class="gbsp" v-if='videoState == true' @click='masksCloseFun'/>
			<video class="xcspbfq" poster="../../assets/img/spfm.png" autoplay controls loop>
				<source src="../../assets/img/TNP.mp4" type="video/mp4">
			</video>
		</div>
		<!-- <div class="pclmk">
			<div class="pclm">
				<div class="pclmbt">
					<div class="pclmbtnr animate__animated" :class="{ animate__fadeInUpBig: pclmybt }" ref="pclmybt">试算附注，函证中心，电子底稿</div>
					<img src="../../assets/img/sdgn.png" class="pclmnrtp animate__animated" :class="{ animate__fadeInUpBig: pclmynr }" ref="pclmynr">
				</div>
				<div class="pclmtp" ref="pcsdgnk">
					<img src="../../assets/img/fzss1.png" class="pcfzss1 animate__animated" :class="{ animate__fadeInUp: pclmyfzss }" ref="pclmyfzss"/>
					<img src="../../assets/img/hzzx1.png" class="pchzzx1 animate__animated" :class="{ animate__fadeInUp: pclmyhzzx }" ref="pclmyhzzx"/>
					<img src="../../assets/img/dzdg1.png" class="pcdzdg1 animate__animated" :class="{ animate__fadeInUp: pclmydzdg }" ref="pclmydzdg"/>
					<img src="../../assets/img/xlts.png" class="pcxlts animate__animated" :class="{ animate__fadeInUpBig: pclmypcxlts }" ref="pclmypcxlts"/>
				</div>
			</div>
		</div>
		<div class="pclmk" style="background: #f5f9fa;">
			<div class="pclm">
				<div class="pcsdgn animate__animated" :class="{ animate__fadeInUp: pcsdgndh }" ref="pcsdgndh">
					<div class="pcsdgnbt">试算附注</div>
					<div class="pcsdgnms">提高审计工作效率，提高审计报告<br>数据准确性</div>
					<img src="../../assets/img/ssfz.png" class="pcssfz"/>
				</div>
				<div class="pcsdgn1 animate__animated" :class="{ animate__fadeInUp: pcsdgn1dh }" ref="pcsdgn1dh">
					<div class="pcsdgnbt">函证中心</div>
					<div class="pcsdgnms">精准控制发函风险，大幅降低<br>发函成本</div>
					<img src="../../assets/img/hzzx.png" class="pcssfz"/>
				</div>
				<div class="pcsdgn2 animate__animated" :class="{ animate__fadeInUp: pcsdgn2dh }" ref="pcsdgn2dh">
					<div class="pcsdgnbt">电子底稿</div>
					<div class="pcsdgnms">加强审计复核控制，告别每年<br>几个月的“补底稿”工作</div>
					<img src="../../assets/img/dzdg.png" class="pcssfz"/>
				</div>
			</div>
		</div>
		<div class="pclmk">
			<div class="pclm">
				<div class="pclmbt" ref="pclmbtdh">
					<div class="pctnpbt animate__animated" :class="{ animate__fadeInRight: pctnpbtdh }" ref="pctnpbtdh">榕树万千TNP系列</div>
					<div class="pctnpnr animate__animated" :class="{ animate__fadeInLeft: pctnpnrdh }" ref="pctnpnrdh">全新64位架构，更快速度，更强性能</div>
				</div>
				<div class="pclmtp" ref="pclmtpdh">
					<img src="../../assets/img/tnpdz.png" class="pctnpdz animate__animated" :class="{ animate__fadeInUp: pctnpdzdh }" ref="pctnpdzdh"/>
					<img src="../../assets/img/tnpg3.png" class="pctnpg3 animate__animated" :class="{ animate__fadeInUp: pctnpg3dh }" ref="pctnpg3dh"/>
					<img src="../../assets/img/tnpg1.png" class="pctnpg1 animate__animated" :class="{ animate__fadeInUp: pctnpg1dh }" ref="pctnpg1dh"/>
					<img src="../../assets/img/tnpg2.png" class="pctnpg2 animate__animated" :class="{ animate__fadeInUp: pctnpg2dh }" ref="pctnpg2dh"/>
					<img src="../../assets/img/tnplogo.png" class="pctnplogo"/>
				</div>
			</div>
		</div>
		<div class="pclmk" style="background: #f5f9fa;">
			<div class="pclm" style="background: #FFFFFF;">
				<div class="pclmtp" ref="tnpjrtpk">
					<img src="../../assets/img/jrxz.png" class="pcjrxz animate__animated" :class="{ animate__fadeInUp: pcjrxzdh }" ref="pcjrxzdh"/>
					<img src="../../assets/img/jrg.png" class="pcjrg animate__animated" :class="{ animate__fadeInDown: pcjrgdh }" ref="pcjrgdh"/>
					<img src="../../assets/img/excel3.png" class="pcexcel3 animate__animated" :class="{ animate__fadeInDown: pcexcel3dh }" ref="pcexcel3dh"/>
					<img src="../../assets/img/excel2.png" class="pcexcel2 animate__animated" :class="{ animate__fadeInDown: pcexcel2dh }" ref="pcexcel2dh"/>
					<img src="../../assets/img/excel1.png" class="pcexcel1 animate__animated" :class="{ animate__fadeInDown: pcexcel1dh }" ref="pcexcel1dh"/>
				</div>
				<div class="pclmbt" ref="pctnpqmjrkdh">
					<div class="pctnpbt animate__animated" :class="{ animate__fadeInUp: pctnpqmjrdh }" ref="pctnpqmjrdh">全面兼容Excel为工作底稿<br>的主流审计软件</div>
				</div>
			</div>
		</div>
		<div class="pclmk">
			<div class="pclm">
				<div class="pclmbt" ref="pcwlhsjdhk">
					<div class="pctnpbt animate__animated" :class="{ animate__fadeInLeft: wlhzdsjdh }" ref="wlhzdsjdh">系统网络化自动升级</div>
					<div class="pctnpnr animate__animated" :class="{ animate__fadeInRight: mssxdh }" ref="mssxdh">所需功能，马上上线</div>
				</div>
				<div class="pclmtp" ref="pcwlhsjtpdhk">
					<img src="../../assets/img/sjdn.png" class="pcsjdn animate__animated" :class="{ animate__fadeInRight: pcsjdndh }" ref="pcsjdndh"/>
					<img src="../../assets/img/sjsj.png" class="pcsjsj animate__animated" :class="{ animate__fadeInLeft: pcsjsjdh }" ref="pcsjsjdh"/>
				</div>
			</div>
		</div>
		<div class="pclmk" style="background: #f5f9fa;">
			<div class="pclm" style="background: #FFFFFF;">
				<div class="pclmbt" ref="zcpgazk">
					<div class="pctnpbt animate__animated" style="margin-top: 30PX;" :class="{ animate__fadeInLeft: pgazbtdh }" ref="pgazbtdh">支持安卓和苹果原生开发APP</div>
				</div>
				<div class="pclmtp" ref="zcpgaztpk">
					<img src="../../assets/img/yskf.png" class="pcyskf animate__animated" :class="{ animate__fadeInUp: pcyskfdh }" ref="pcyskfdh"/>
					<img src="../../assets/img/czxxts.png" class="pcczxxts animate__animated" :class="{ animate__fadeInUp: pcczxxtsdh }" ref="pcczxxtsdh"/>
				</div>
			</div>
		</div> -->
		<div class="pclmk">
			<div class="pclm">
				<div class="pclmtp1" ref="pcdyhtpk">
					<img src="../../assets/img/gzh.png" class="pcdyh1 pcdyhdh1" ref="pcdyhdh1"/>
				</div>
				<div class="pclmbt1" ref="pcdyhwzk">
					<div class="pctnpbt gzdyhwzdh" ref="gzdyhwzdh">关注我们了解最新动态</div>
					<div class="dywzsm" ref="gzdyhwzdh">微信扫描二维码或<br>直接搜索榕树万千李金鹏</div>
				</div>
			</div>
		</div>
		<div class="pclxwmk pclxwmkdh" id="lxwm" ref="pclxwmkdh"></div>
		<div class="pclxwmsrkbj pclxwmsrkbjdh" ref="pclxwmsrkbjdh">
			<div class="pclxwmcn">留下您的联系方式我们承诺将在2个工作日内联系您</div>
			<div class="lxwminputk">
				<div class="lxwmsrkyg">
					<div class="pclxwmsrkbt">事务所</div>
					<input class="pcsws" type="text" v-model="fromdata.sws"/>
				</div>
				<div class="lxwmsrkyg">
					<div class="pclxwmsrkbt">职务</div>
					<select class="pcsws" v-model="fromdata.zw">
					  <option value ="合伙人">合伙人</option>
					  <option value ="审计经理">部门经理</option>
					  <option value="项目经理">审计经理</option>
					  <option value="质监部">质监部</option>
					  <option value="信息部">信息部</option>
					  <option value="技术部">技术部</option>
					  <option value="综合部">综合部</option>
					  <option value="其他部门">其他部门</option>
					</select>
				</div>
				<div class="lxwmsrkyg">
					<div class="pclxwmsrkbt">姓名</div>
					<input class="pcsws" type="text" v-model="fromdata.xm"/>
				</div>
				<div class="lxwmsrkyg">
					<div class="pclxwmsrkbt">手机号</div>
					<input class="pcsws" type="text" v-model="fromdata.sjh"/>
				</div>
				<div class="lxwmsrkyg">
					<div class="pclxwmsrkbt">联系时间</div>
					<div class="pcdxk">
						<div class="gzsj" :class="{ xz:gzsj }" @click="lxsj(1)">
							<div class="yd" :class="{ xzyd:gzsj }"></div>
							工作时间
						</div>
						<div class="gzsj" :class="{ xz:fgzsj }" @click="lxsj(2)">
							<div class="yd" :class="{ xzyd:fgzsj }"></div>
							任意时间
						</div>
					</div>
				</div>
				<div class="lxwmsrkyg" style="width: 100%;">
					<div class="pclxwmsrkbt">您的问题</div>
					<div class="pcdxk">
						<div class="gzsj" :class="{ xz:azwt }" @click="ndwt(1)">
							<div class="yd" :class="{ xzyd:azwt }"></div>
							安装问题
						</div>
						<div class="gzsj" :class="{ xz:xszx }" @click="ndwt(2)">
							<div class="yd" :class="{ xzyd:xszx }"></div>
							销售咨询
						</div>
					</div>
					<div class="pcxljdcpk" v-if="fromdata.wt == '安装问题'">
						<div class="xyljdcpxx" @click="bddxff(7)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.ncbg }"></div>
							内存不够
						</div>
						<div class="xyljdcpxx" @click="bddxff(8)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.azbl }"></div>
							安装不上
						</div>
						<div class="xyljdcpxx" @click="bddxff(9)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.sjbl }"></div>
							升级不了
						</div>
						<div class="xyljdcpxx" @click="bddxff(10)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.zcbl }"></div>
							注册不了
						</div>
					</div>
					<div class="pcxljdcpk" v-if="fromdata.wt == '销售咨询'">
						<div class="xyljdcpxx" @click="bddxff(2)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.ssfzxx }"></div>
							网络表格及文档平台
						</div>
						<div class="xyljdcpxx" @click="bddxff(3)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.hzzxxx }"></div>
							会计师事务所管理系统
						</div>
						<div class="xyljdcpxx" @click="bddxff(4)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.dzdgxx }"></div>
							会计师事务所函证中心
						</div>
						<div class="xyljdcpxx" @click="bddxff(5)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.czcqgj }"></div>
							取数查账抽凭工具
						</div>
						<div class="xyljdcpxx" @click="bddxff(6)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.bgtxgj }"></div>
							报告赋码自动填写工具
						</div>
					</div>
				</div>
			</div>
			<div class="pctj" v-if="tjqny == 0" @click="lxwm()">提交</div>
			<div class="pctj1" v-if="tjqny == 1">{{tjants}}</div>
			<div class="pcbtts" :class="{ bttsbxs: bttsxs }">{{btss}}</div>
		</div>
		<div class="foot">
			<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021002230号-1</a><br>
			北京独木成林科技有限公司<br>
			Beijing One Tree Forest Technology Co., Ltd.
		</div>
	</div>
</template>

<style scoped>
.dywzsm{
	width: 100%;
	font-size: 20PX;
	color: #666666;
	text-align: center;
	margin-top: 20PX;
}
.wxwzsm{
	width: 280PX;
	position: absolute;
	right: 0;
	bottom: 50PX;
	font-size: 20PX;
	color: #666666;
	text-align: center;
}
.dxyd{
	width: 18PX;
	height: 18PX;
	border-radius: 9PX;
	border: 1PX solid #68747f;
	position: absolute;
	left: 15PX;
	top: 9PX;
	box-sizing: border-box;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.dxydxz{
	border: 6PX solid #05ac73;
}
.xyljdcpxx{
	width: 20%;
	font-size: 15PX;
	color: #616076;
	line-height: 36PX;
	position: relative;
	box-sizing: border-box;
	padding-left: 45PX;
	text-align: left;
	float: left;
	cursor: pointer;
}
.xyljdcpxx:hover{
	background: #eef5f7;
}
.pcxljdcpk{
	width: 100%;
	height: 36PX;
	background: #f8fafc;
	margin-top: 15PX;
}
.pcdyh{
	display: block;
	width: 280PX;
	box-shadow: 0 0 43PX 0 rgba(6, 0, 1, 0.1);
	position: absolute;
	right: 0;
	top: 45PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pcdyh1{
	display: block;
	width: 280PX;
	box-shadow: 0 0 43PX 0 rgba(6, 0, 1, 0.1);
	position: absolute;
	left: 250PX;
	top: 45PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pcbtts{
	width: 100%;
	font-size: 17PX;
	color: #F56C6C;
	text-align: center;
	margin-top: 30PX;
}
.bttsbxs{
	display: none;
}
.xcspbfq{
	width: 1200PX;
	margin:auto;
	position: absolute;
	z-index: 9998;
	top:0;
	bottom:0;
	left:0;
	right:0;
}
.gbsp{
	position: absolute;
	width: 60PX;
	top: 60PX;
	z-index: 9999;
	right: 43PX;
	top: 43PX;
	cursor: pointer;
}
.videomasks{
	position: fixed;
	left: 0;
	top: 0;
	background: rgb(0,0,0,0.5);
	width: 100%;
	height: 100%;
	z-index: 9997;
}
.pctj1{
	height: 40PX;
	width: 350PX;
	background: #EBEEF5;
	border-radius: 5PX;
	font-size: 16PX;
	line-height: 40PX;
	color: #909399;
	margin: 0 auto;
	text-align: center;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.pctj{
	height: 40PX;
	width: 350PX;
	background: #05ac73;
	border-radius: 5PX;
	font-size: 16PX;
	line-height: 40PX;
	color: #FFFFFF;
	cursor: pointer;
	margin: 0 auto;
	text-align: center;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.pctj:hover{
	background: #18cf91;
}
.yd{
	width: 14PX;
	height: 14PX;
	border-radius: 7PX;
	border: 1PX solid #68747f;
	position: absolute;
	left: 15PX;
	top: 10PX;
	box-sizing: border-box;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.gzsj{
	height: 100%;
	box-sizing: border-box;
	padding-left: 40PX;
	padding-right: 15PX;
	position: relative;
	color: #68747f;
	font-size: 15PX;
	line-height: 34PX;
	text-align: center;
	border: 1PX solid #e0e0e0;
	float: left;
	line-height: 34PX;
	border-radius: 5PX;
	margin-right: 30PX;
	cursor: pointer;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.xz{
	border-color: #05ac73;
	color: #05ac73;
}
.xzyd{
	border: 5PX solid #05ac73;
}
.pcdxk{
	height: 34PX;
	width: 100%;
	margin-top: 15PX;
	margin-bottom: 25PX;
}
.pcsws:focus{
	outline:none;
	border: 1PX solid #05ac73;
}
.pcsws{
	width: 100%;
	height: 34PX;
	background: #FFFFFF;
	border: 1PX solid #e0e0e0;
	font-size: 15PX;
	color: #616076;
	box-sizing: border-box;
	padding-left: 10PX;
	padding-right: 10PX;
	line-height: 34PX;
	margin-top: 15PX;
	margin-bottom: 25PX;
}
.pclxwmsrkbt{
	width: 100%;
	font-size: 18PX;
	color: #68747f;
	text-align: left;
}
.lxwmsrkyg{
	width: 33.3%;
	box-sizing: border-box;
	padding-left: 30PX;
	padding-right: 30PX;
	float: left;
}
.lxwminputk{
	width: 100%;
	margin-top: 50PX;
	margin-bottom: 30PX;
	overflow: hidden;
}
.pclxwmcn{
	width: 100%;
	color: #68747f;
	text-align: center;
	font-size: 20PX;
}
.pclxwmsrkbj{
	width: 1200PX;
	margin: 0 auto;
	background: #FFFFFF;
	box-shadow: 0 0 14PX 0 rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	padding-top: 50PX;
	padding-bottom: 50PX;
	margin-top: -150PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.pclxwmk{
	width: 100%;
	min-width: 1200PX;
	height: 230PX;
	background-image: url(../../assets/img/pclxwm.png);
	background-repeat: no-repeat;
	background-position: center center;
}
.pcczxxts{
	display: block;
	position: absolute;
	width: 128PX;
	right: 77PX;
	top: 84PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.7s;
	-webkit-animation-delay: 0.7s;
	-o-animation-delay: 0.7s;
	animation-delay: 0.7s;
}
.pcyskf{
	display: block;
	position: absolute;
	width: 460PX;
	left: 77PX;
	top: 84PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
	-o-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.pcsjsj{
	display: block;
	position: absolute;
	width: 98PX;
	left: 61PX;
	bottom: 137PX;
	z-index: 2;
}
.pcsjdn{
	display: block;
	position: absolute;
	width: 480PX;
	left: 61PX;
	bottom: 137PX;
	z-index: 1;
}
.pcexcel3{
	display: block;
	position: absolute;
	width: 74PX;
	left: 195PX;
	bottom: 310PX;
	z-index: 4;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
	-o-animation-delay: 2.5s;
	animation-delay: 2.5s;
}
.pcexcel2{
	display: block;
	position: absolute;
	width: 80PX;
	left: 310PX;
	bottom: 320PX;
	z-index: 3;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 2s;
	-webkit-animation-delay: 2s;
	-o-animation-delay: 2s;
	animation-delay: 2s;
}
.pcexcel1{
	display: block;
	position: absolute;
	width: 66PX;
	left: 261PX;
	bottom: 244PX;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1.5s;
	-webkit-animation-delay: 1.5s;
	-o-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.pcjrg{
	display: block;
	position: absolute;
	width: 330PX;
	left: 136PX;
	top: 85PX;
	z-index: 5;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1s;
	-webkit-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;
}
.pcjrxz{
	display: block;
	position: absolute;
	width: 290PX;
	left: 154PX;
	bottom: 85PX;
	z-index: 1;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
@keyframes logodh{0%{ bottom:288PX;}50%{ bottom:310PX;}100%{ bottom:288PX;}}
@-moz-keyframes logodh{0%{ bottom:288PX;}50%{ bottom:310PX;}100%{ bottom:288PX;}}
@-webkit-keyframes logodh{0%{ bottom:288PX;}50%{ bottom:310PX;}100%{ bottom:288PX;}}
@-o-keyframes logodh{0%{ bottom:288PX;}50%{ bottom:310PX;}100%{ bottom:288PX;}}
.pctnplogo{
	display: block;
	position: absolute;
	width: 78PX;
	left: 261PX;
	bottom: 288PX;
	z-index: 3;
	animation:logodh 2s infinite linear;
	-moz-animation:logodh 2s infinite linear;
	-webkit-animation:logodh 2s infinite linear;
	-o-animation:logodh 2s infinite linear;
}
.pctnpg2{
	display: block;
	position: absolute;
	width: 52PX;
	left: 273PX;
	bottom: 241PX;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.pctnpg1{
	display: block;
	position: absolute;
	width: 205PX;
	left: 197PX;
	bottom: 196PX;
	z-index: 4;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.pctnpg3{
	display: block;
	position: absolute;
	width: 290PX;
	left: 155PX;
	bottom: 162PX;
	z-index: 5;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.pctnpdz{
	display: block;
	position: absolute;
	width: 290PX;
	left: 155PX;
	bottom: 84PX;
	z-index: 1;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.pctnpnr{
	width: 100%;
	color: #68747f;
	text-align: center;
	font-size: 26PX;
	margin-top: 25PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pctnpbt{
	width: 100%;
	color: #3e464c;
	text-align: center;
	font-size: 30PX;
	font-weight: bold;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pcsdgn2{
	width:calc(33.3% - 30PX);
	height: 100%;
	background: rgb(255,255,255,1);
	float: left;
	box-sizing: border-box;
	padding-top: 50PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1.2s;
	-webkit-animation-delay: 1.2s;
	-o-animation-delay: 1.2s;
	animation-delay: 1.2s;
}
.pcsdgn1{
	width:calc(33.3% - 30PX);
	height: 100%;
	background: rgb(255,255,255,1);
	float: left;
	margin-right: 45PX;
	box-sizing: border-box;
	padding-top: 50PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.7s;
	-webkit-animation-delay: 0.7s;
	-o-animation-delay: 0.7s;
	animation-delay: 0.7s;
}
.pcssfz{
	display: block;
	width: 300PX;
	margin: 0 auto;
}
.pcsdgnms{
	width: 100%;
	color: #3e464c;
	text-align: center;
	font-size: 20PX;
	color: #68747f;
	text-align: center;
	margin-top: 30PX;
	margin-bottom: 50PX;
	line-height: 35PX;
}
.pcsdgnbt{
	width: 100%;
	color: #3e464c;
	text-align: center;
	font-size: 26PX;
	font-weight: bold;
}
.pcsdgn{
	width:calc(33.3% - 30PX);
	height: 100%;
	background: rgb(255,255,255,1);
	float: left;
	margin-right: 45PX;
	box-sizing: border-box;
	padding-top: 50PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pcdzdg1{
	width: 180PX;
	display: block;
	position: absolute;
	right: 121PX;
	top: 78PX;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 2s;
	-webkit-animation-delay: 2s;
	-o-animation-delay: 2s;
	animation-delay: 2s;
}
.pchzzx1{
	width: 180PX;
	display: block;
	position: absolute;
	left: 121PX;
	top: 78PX;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1.5s;
	-webkit-animation-delay: 1.5s;
	-o-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.pcfzss1{
	width: 180PX;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -90PX;
	top: 14PX;
	z-index: 1;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1s;
	-webkit-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;
}
.pcxlts{
	height: 45PX;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -152PX;
	bottom: 44PX;
	z-index: 1;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pclmnrtp{
	width: 380PX;
	display: block;
	margin: 0 auto;
	margin-top: 22PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
}
.pclmbtnr{
	width: 100%;
	color: #3e464c;
	text-align: center;
	font-size: 30PX;
	font-weight: bold;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
}
.pclmtp{
	width: 50%;
	height: 100%;
	box-sizing: border-box;
	float: left;
	overflow: hidden;
	position: relative;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pclmbt{
	width: 50%;
	height: 100%;
	box-sizing: border-box;
	float: left;
	overflow: hidden;
	position: relative;
	padding-top: 200PX;
}
.pclmtp1{
	width: 55%;
	height: 100%;
	box-sizing: border-box;
	float: left;
	overflow: hidden;
	position: relative;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.pclmbt1{
	width: 45%;
	height: 100%;
	box-sizing: border-box;
	float: left;
	overflow: hidden;
	position: relative;
	padding-top: 140PX;
}
.pclm{
	width: 1200PX;
	height: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	overflow: hidden;
}
.pclmk{
	width: 100%;
	height: 560PX;
	box-sizing: border-box;
	padding-top: 58PX;
}
.pcbf{
	width: 100PX;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -50PX;
	top: 200PX;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	cursor:pointer;
}
.pcbf:hover{
	opacity: 0.8;
}
.pcbannerk{
	width: 100%;
	height: 500PX;
	overflow: hidden;
	position: relative;
	background-image: url(../../assets/img/pcbanner.png);
	background-repeat: no-repeat;
	background-position: center center;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
}
.pcpage{
	width: 100%;
	min-width: 1200PX;
	overflow-x:auto;
}
.pchade{
	width: 100%;
	background: #FFFFFF;
	border-bottom: 1PX solid #f5f5f5;
	height: 100PX;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
}
.pchanek{
	width: 1200PX;
	height: 100%;
	margin: 0 auto;
	position: relative;
}
.pclogo{
	height: 68PX;
	display: block;
	position: absolute;
	left: 0;
	top: 16PX;
}
.pclxwm{
	width: 108PX;
	height: 42PX;
	background: #60cda4;
	border-radius: 6PX;
	font-size: 16PX;
	line-height:42PX;
	color: #FFFFFF;
	text-align: center;
	position: absolute;
	right: 0;
	top: 29PX;
	cursor:pointer;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.pclxwm:hover{
	background: #11c584;
}
.foot{
	width: 100%;
	background: #f5f9fa;
	margin-top: 58PX;
	color: #68747f;
	line-height: 25PX;
	text-align: center;
	font-size: 13PX;
	padding-top: 20PX;
	padding-bottom: 20PX;
}
.foot a{
	color: #68747f!important;
	cursor: pointer;
}
.animate__animated{
	opacity: 0;
}
</style>

<script>
import wxapi from '../yd/wxapi.js';
export default {
	name: 'pc',
	data () {
		return {
			gzsj: true,
			fgzsj: false,
			azwt: false,
			xszx: false,
			videoState:false,
			isShowSelect: false,
			wh: 0,
			btss:'',
			bttsxs:true,
			tjqny:0,
			tjants:'提交中',
			bddxxx:{
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false,
				bgtxgj:false
			},
			azwtlb:{
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			},
			fromdata:{
			  sws: '',
			  zw:'',
			  xm: '',
			  sjh: '',
			  sj: '工作时间',
			  lj:'',
			  wt:''
			},
			ly:'pc',
			tjr:'',
			pclmybt:false,
			pclmynr:false,
			pclmyfzss:false,
			pclmyhzzx:false,
			pclmydzdg:false,
			pclmypcxlts:false,
			pcsdgndh:false,
			pcsdgn1dh:false,
			pcsdgn2dh:false,
			pctnpbtdh:false,
			pctnpnrdh:false,
			pctnpdzdh:false,
			pctnpg3dh:false,
			pctnpg1dh:false,
			pctnpg2dh:false,
			pctnpqmjrdh:false,
			pcjrxzdh:false,
			pcjrgdh:false,
			pcexcel3dh:false,
			pcexcel2dh:false,
			pcexcel1dh:false,
			wlhzdsjdh:false,
			mssxdh:false,
			pcsjdndh:false,
			pcsjsjdh:false,
			pgazbtdh:false,
			pcyskfdh:false,
			pcczxxtsdh:false,
			pcdyhdh1:false,
			gzdyhwzdh:false,
			pclxwmkdh:false,
			pclxwmsrkbjdh:false,
			dysmewmwz:false
		}
	},
	mounted () {
		let that = this;
		if(that.$route.params.id){
			that.tjr = that.$route.params.id;
		}
		let broser = window.navigator.userAgent.toLowerCase();
		if(broser.match(/MicroMessenger/i) == 'micromessenger'){
		     that.ly = "wx";
		}
		this.$http('/browse?source=' + that.ly + '&tjr=' + that.tjr).then(function (response) {
			
		}).catch(function (error) {
			
		});
		// window.addEventListener('scroll', this.handleScroll);
		// this.wh = window.innerHeight - 100;
		// this.handleScroll();
	},
	// beforeDestroy() {
	// 	window.removeEventListener("scroll",this.handleScroll)
	// },
	methods: {
		golxwm(){
		   document.querySelector("#lxwm").scrollIntoView(true);
		},
		lxsj(lx){
			let that = this;
			if(lx == 1){
				that.gzsj = true;
				that.fgzsj = false;
				that.fromdata.sj = "工作时间";
			}else{
				let that = this;
				that.gzsj = false;
				that.fgzsj = true;
				that.fromdata.sj = "任意时间";
			}
		},
		ndwt(lx){
			let that = this;
			if(lx == 1){
				that.azwt = true;
				that.xszx = false;
				that.fromdata.wt = "安装问题";
			}else{
				that.azwt = false;
				that.xszx = true;
				that.fromdata.wt = "销售咨询";
			}
			that.bddxxx = {
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false,
				bgtxgj:false
			}
			that.azwtlb = {
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			}
		},
		bddxff(xx){
			let that = this;
			if(xx == 2){
				if(that.bddxxx.ssfzxx == false){
					that.bddxxx.ssfzxx = true;
				}else{
					that.bddxxx.qbxx = false;
					that.bddxxx.ssfzxx = false;
				}
			}
			if(xx == 3){
				if(that.bddxxx.hzzxxx == false){
					that.bddxxx.hzzxxx = true;
				}else{
					that.bddxxx.qbxx = false;
					that.bddxxx.hzzxxx = false;
				}
			}
			if(xx == 4){
				if(that.bddxxx.dzdgxx == false){
					that.bddxxx.dzdgxx = true;
				}else{
					that.bddxxx.dzdgxx = false;
				}
			}
			if(xx == 5){
				if(that.bddxxx.czcqgj == false){
					that.bddxxx.czcqgj = true;
				}else{
					that.bddxxx.czcqgj = false;
				}
			}
			if(xx == 6){
				if(that.bddxxx.bgtxgj == false){
					that.bddxxx.bgtxgj = true;
				}else{
					that.bddxxx.bgtxgj = false;
				}
			}
			if(xx == 7){
				if(that.azwtlb.ncbg == false){
					that.azwtlb.ncbg = true;
				}else{
					that.azwtlb.ncbg = false;
				}
			}
			if(xx == 8){
				if(that.azwtlb.azbl == false){
					that.azwtlb.azbl = true;
				}else{
					that.azwtlb.azbl = false;
				}
			}
			if(xx == 9){
				if(that.azwtlb.sjbl == false){
					that.azwtlb.sjbl = true;
				}else{
					that.azwtlb.sjbl = false;
				}
			}
			if(xx == 10){
				if(that.azwtlb.zcbl == false){
					that.azwtlb.zcbl = true;
				}else{
					that.azwtlb.zcbl = false;
				}
			}
		},
		checkVideoFun(){
			this.videoState = true;
		},
		masksCloseFun(){
			this.videoState = false;
		},
		lxwm(){
			let that = this;
			that.fromdata.lj = '';
			if(that.fromdata.wt == '安装问题'){
				if(that.azwtlb.ncbg == true){
					that.fromdata.lj = that.fromdata.lj + '内存不够';
				}
				if(that.azwtlb.azbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '安装不上';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，安装不上';
					}
				}
				if(that.azwtlb.sjbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '升级不了';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，升级不了';
					}
				}
				if(that.azwtlb.zcbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '注册不了';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，注册不了';
					}
				}
			}else if(that.fromdata.wt == '销售咨询'){
				if(that.bddxxx.ssfzxx == true){
					that.fromdata.lj = that.fromdata.lj + '网络表格及文档平台';
				}
				if(that.bddxxx.hzzxxx == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '会计师事务所管理系统';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，会计师事务所管理系统';
					}
				}
				if(that.bddxxx.dzdgxx == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '会计师事务所函证中心';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，会计师事务所函证中心';
					}
				}
				if(that.bddxxx.czcqgj == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '取数查账抽凭工具';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，取数查账抽凭工具';
					}
				}
				if(that.bddxxx.bgtxgj == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '报告赋码自动填写工具';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，报告赋码自动填写工具';
					}
				}
			}
			if(that.fromdata.lj==''){
				that.btss = '请选择您的问题！';
				that.bttsxs = false;
			}else if(that.fromdata.sws == '' || that.fromdata.zw == '' || that.fromdata.sjh == '' || that.fromdata.xm == '' || that.fromdata.wt == ''){
				that.btss = '请填写完整信息！';
				that.bttsxs = false;
			}else if(!(/^1[23456789]\d{9}$/.test(this.fromdata.sjh))){
				that.btss = '请确认手机号是否正确！';
				that.bttsxs = false;
			}else{
				that.btss = '';
				that.bttsxs = true;
				that.tjqny = 1;
				that.tjants = '提交中';
				this.$http.post('/contact', {
					wt: that.fromdata.wt,
					sws: that.fromdata.sws,
					zw:that.fromdata.zw,
					xm: that.fromdata.xm,
					sjh:that.fromdata.sjh,
					sj:that.fromdata.sj,
					lj:that.fromdata.lj,
					ly:that.ly,
					tjr:that.tjr
				  })
				  .then(function (response) {
					  if(response.data.errmsg=='ok'){
						  alert('提交成功！')
						  that.tjants = '提交成功';
						  that.fromdata.sws = '';
						  that.fromdata.zw = '';
						  that.fromdata.xm = '';
						  that.fromdata.sjh = '';
						  that.fromdata.lj = '';
					  }else{
						  alert('网络异常，请再次提交！')
						  that.btss = '';
						  that.tjqny = 0;
						  that.bttsxs = false;
					  }
				  }).catch(function (error) {
				    alert('网络异常，请再次提交！')
					that.btss = '';
					that.tjqny = 0;
					that.bttsxs = false;
				  });
			}
		},
		// handleScroll() {
		// 	this.currentScroll = window.pageYOffset;
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcsdgnk.offsetTop + this.$refs.pclmybt.offsetTop)) {
		// 		this.pclmybt = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcsdgnk.offsetTop + this.$refs.pclmynr.offsetTop)) {
		// 		this.pclmynr = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcsdgnk.offsetTop + this.$refs.pclmyfzss.offsetTop)) {
		// 		this.pclmyfzss = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcsdgnk.offsetTop + this.$refs.pclmyhzzx.offsetTop)) {
		// 		this.pclmyhzzx = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcsdgnk.offsetTop + this.$refs.pclmydzdg.offsetTop)) {
		// 		this.pclmydzdg = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcsdgnk.offsetTop + this.$refs.pclmypcxlts.offsetTop)) {
		// 		this.pclmypcxlts = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.pcsdgndh.offsetTop) {
		// 		this.pcsdgndh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.pcsdgn1dh.offsetTop) {
		// 		this.pcsdgn1dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.pcsdgn2dh.offsetTop) {
		// 		this.pcsdgn2dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pclmbtdh.offsetTop + this.$refs.pctnpbtdh.offsetTop)) {
		// 		this.pctnpbtdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pclmbtdh.offsetTop + this.$refs.pctnpnrdh.offsetTop)) {
		// 		this.pctnpnrdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pclmtpdh.offsetTop + this.$refs.pctnpdzdh.offsetTop)) {
		// 		this.pctnpdzdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pclmtpdh.offsetTop + this.$refs.pctnpg3dh.offsetTop)) {
		// 		this.pctnpg3dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pclmtpdh.offsetTop + this.$refs.pctnpg1dh.offsetTop)) {
		// 		this.pctnpg1dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pclmtpdh.offsetTop + this.$refs.pctnpg2dh.offsetTop)) {
		// 		this.pctnpg2dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pctnpqmjrkdh.offsetTop + this.$refs.pctnpqmjrdh.offsetTop)) {
		// 		this.pctnpqmjrdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.tnpjrtpk.offsetTop + this.$refs.pcjrxzdh.offsetTop)) {
		// 		this.pcjrxzdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.tnpjrtpk.offsetTop + this.$refs.pcjrgdh.offsetTop)) {
		// 		this.pcjrgdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.tnpjrtpk.offsetTop + this.$refs.pcexcel3dh.offsetTop)) {
		// 		this.pcexcel3dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.tnpjrtpk.offsetTop + this.$refs.pcexcel2dh.offsetTop)) {
		// 		this.pcexcel2dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.tnpjrtpk.offsetTop + this.$refs.pcexcel1dh.offsetTop)) {
		// 		this.pcexcel1dh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcwlhsjdhk.offsetTop + this.$refs.wlhzdsjdh.offsetTop)) {
		// 		this.wlhzdsjdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcwlhsjdhk.offsetTop + this.$refs.mssxdh.offsetTop)) {
		// 		this.mssxdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcwlhsjtpdhk.offsetTop + this.$refs.pcsjdndh.offsetTop)) {
		// 		this.pcsjdndh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcwlhsjtpdhk.offsetTop + this.$refs.pcsjsjdh.offsetTop)) {
		// 		this.pcsjsjdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.zcpgazk.offsetTop + this.$refs.pgazbtdh.offsetTop)) {
		// 		this.pgazbtdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.zcpgaztpk.offsetTop + this.$refs.pcyskfdh.offsetTop)) {
		// 		this.pcyskfdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.zcpgaztpk.offsetTop + this.$refs.pcczxxtsdh.offsetTop)) {
		// 		this.pcczxxtsdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcdyhtpk.offsetTop + this.$refs.pcdyhdh1.offsetTop)) {
		// 		this.pcdyhdh1 = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcdyhtpk.offsetTop + this.$refs.pcdyhdh1.offsetTop)) {
		// 		this.pcdyhdh1 = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcdyhwzk.offsetTop + this.$refs.dysmewmwz.offsetTop)) {
		// 		this.dysmewmwz = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.pcdyhwzk.offsetTop + this.$refs.gzdyhwzdh.offsetTop)) {
		// 		this.gzdyhwzdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.pclxwmkdh.offsetTop) {
		// 		this.pclxwmkdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.pclxwmsrkbjdh.offsetTop) {
		// 		this.pclxwmsrkbjdh = true;
		// 	}
		// }
	}
};
</script>
