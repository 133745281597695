<template>
	<div>
		<yd v-if="equipment == 'y'"/>
		<pc v-if="equipment == 'p'"/>
	</div>
</template>

<script>
import yd from './yd/home.vue';
import pc from './pc/home.vue';
export default {
	name: 'index',
	data () {
		return {
			equipment:''
		}
	},
	components: {
	  yd,
	  pc
	},
	mounted () {
		let that = this;
		if (that._isMobile()) {
		    that.equipment = 'y';//手机端
		 } else {
		   that.equipment = 'p';//PC端
		 }
	},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
			return flag;
		}
	}
};
</script>
