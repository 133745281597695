<template>
	<div class="page">
		<div class="hade animate__animated animate__fadeInDown">
			<img src="../../assets/img/logo1.png" class="logo"/>
		</div>
		<div class="banner animate__animated animate__fadeInLeft">
			<img src="../../assets/img/bf.png" class="bf" @click='checkVideoFun()'/>
		</div>
		<div v-if="videoState == true" class="videomasks">
			<img src="../../assets/img/gbsp.png" class="gbsp" v-if='videoState == true' @click='masksCloseFun'/>
			<video class="xcspbfq" poster="../../assets/img/spfm.png" autoplay controls loop>
				<source src="../../assets/img/TNP.mp4" type="video/mp4">
			</video>
		</div>
		<!-- <div class="page1">
			<div class="sdgnbt animate__animated animate__fadeInRight">试算附注,函证中心,电子底稿</div>
			<img src="../../assets/img/sdgn.png" class="sdgn animate__animated animate__fadeInUp">
			<div class="syzzk">
				<img src="../../assets/img/fzss1.png" class="fzss1 animate__animated animate__fadeInUp" />
				<img src="../../assets/img/hzzx1.png" class="hzzx1 animate__animated animate__fadeInUp" />
				<img src="../../assets/img/dzdg1.png" class="dzdg1 animate__animated animate__fadeInUp" />
				<img src="../../assets/img/xlts.png" class="xlts animate__animated" :class="{ animate__fadeInUpBig: jgywc }" ref="jgywc"/>
			</div>
		</div>
		<div class="page2">
			<div class="sdgnxk sdgnxkmg animate__animated" :class="{ animate__fadeInLeft: ssfzbsk }" ref="ssfzbsk">
				<div class="wlhbt" >试算附注</div>
				<div class="wlhnr">提高审计工作效率，提高审计报告<br>数据准确性</div>
				<img src="../../assets/img/ssfz.png" class="ssfz"/>
			</div>
			<div class="sdgnxk sdgnxkmg animate__animated" :class="{ animate__fadeInRight: hzzxbsk }" ref="hzzxbsk">
				<div class="wlhbt">函证中心</div>
				<div class="wlhnr">精准控制发函风险，大幅降低发函成本</div>
				<img src="../../assets/img/hzzx.png" class="ssfz"/>
			</div>
			<div class="sdgnxk animate__animated" :class="{ animate__fadeInLeft: dzdgsk }" ref="dzdgsk">
				<div class="wlhbt">电子底稿</div>
				<div class="wlhnr">加强审计复核控制，告别每年几个月的<br>“补底稿”工作</div>
				<img src="../../assets/img/dzdg.png" class="ssfz"/>
			</div>
		</div>
		<div class="pag3">
			<div class="wlhbt3 animate__animated" :class="{ animate__fadeInRight: tnpbb }" ref="tnpbb">榕树万千TNP系列</div>
			<div class="wlhnr3 animate__animated" :class="{ animate__fadeInLeft: qxlss }" ref="qxlss">全新64位架构，更快速度，更强性能</div>
			<div class="TNP" ref="tnptprq">
				<img src="../../assets/img/tnpdz.png" class="tnpdz animate__animated" :class="{ animate__fadeInUp: dz }" ref="dz"/>
				<img src="../../assets/img/tnpg3.png" class="tnpg3 animate__animated" :class="{ animate__fadeInRight: gs }" ref="gs"/>
				<img src="../../assets/img/tnpg1.png" class="tnpg1 animate__animated" :class="{ animate__fadeInLeft: gy }" ref="gy"/>
				<img src="../../assets/img/tnpg2.png" class="tnpg2 animate__animated" :class="{ animate__fadeInRight: ge }" ref="ge"/>
				<img src="../../assets/img/tnplogo.png" class="tnplogo" />
			</div>
		</div>
		<div class="page6">
			<div class="sdgnxk" ref="qmjrnrk">
				<div class="wlhbt animate__animated" :class="{ animate__fadeInLeft: qmjrbt }" ref="qmjrbt">全面兼容Excel为工作底稿<br>的主流审计软件</div>
				<div class="jrxzk">
					<img src="../../assets/img/jrxz.png" class="jrxz animate__animated" :class="{ animate__fadeInUp: qmjrexcelxz }" ref="qmjrexcelxz"/>
					<img src="../../assets/img/jrg.png" class="jrg animate__animated" :class="{ animate__fadeInDown: qmjrexcelg }" ref="qmjrexcelg"/>
					<img src="../../assets/img/excel3.png" class="excel3 animate__animated" :class="{ animate__fadeInDown: qmjrexcel3 }" ref="qmjrexcel3"/>
					<img src="../../assets/img/excel2.png" class="excel2 animate__animated" :class="{ animate__fadeInDown: qmjrexcel2 }" ref="qmjrexcel2"/>
					<img src="../../assets/img/excel1.png" class="excel1 animate__animated" :class="{ animate__fadeInDown: qmjrexcel1 }" ref="qmjrexcel1"/>
				</div>
			</div>
		</div>
		<div class="page5">
			<div class="wlhbt1 animate__animated" :class="{ animate__fadeInLeft: wlhsjbt }" ref="wlhsjbt">系统网络化自动升级</div>
			<div class="wlhbt2 animate__animated" :class="{ animate__fadeInRight: wlhsjnr }" ref="wlhsjnr">所需功能，马上上线</div>
			<div class="zdsjtpk" ref="sjdhk">
				<img src="../../assets/img/sjdn.png" class="sjdn animate__animated" :class="{ animate__fadeInRight: sjdndh }" ref="sjdndh"/>
				<img src="../../assets/img/sjsj.png" class="sjsj animate__animated" :class="{ animate__fadeInLeft: sjsjdh }" ref="sjsjdh"/>
			</div>
		</div>
		<div class="page4">
			<div class="sdgnxk" ref="sjappk">
				<div class="wlhbt animate__animated" :class="{ animate__fadeInLeft: zcappbt }" ref="zcappbt">支持安卓和苹果原生开发APP</div>
				<div class="yskfk" ref="appzs">
					<img src="../../assets/img/yskf.png" class="yskf animate__animated" :class="{ animate__fadeInUp: sjjmzstp }" ref="sjjmzstp"/>
					<img src="../../assets/img/czxxts.png" class="czxxts animate__animated" :class="{ animate__fadeInUp: sjjmzsts }" ref="sjjmzsts"/>
				</div>
			</div>
		</div> -->
		<div class="page8">
			<div class="wlhbtdy" ref="gzdybt">关注我们了解最新动态</div>
			<div class="wlhnrdy1" ref="gzdyms1">微信扫码二维码或<br>直接搜索榕树万千李金鹏</div>
			<img src="../../assets/img/wxgzhgz.png" class="gzdytp1" ref="gzdytp1"/>
		</div>
		<div class="page7" id="lxwm">
			<div class="lxwmk">
				<img src="../../assets/img/lxwm.png" class="lxwm lxwmtp" ref="lxwmtp"/>
				<div class="lxwmsrk lxwmsrkdh" ref="lxwmsrkdh">
					<div class="lxwmbt">留下您的联系方式<br>我们承诺将在2个工作日内联系您</div>
					<div class="srkbt">事务所</div>
					<input class="sws" type="text" v-model="fromdata.sws"/>
					<div class="srkbt">职务</div>
					<select class="sws" v-model="fromdata.zw">
					  <option value ="合伙人">合伙人</option>
					  <option value ="审计经理">部门经理</option>
					  <option value="项目经理">审计经理</option>
					  <option value="质监部">质监部</option>
					  <option value="信息部">信息部</option>
					  <option value="技术部">技术部</option>
					  <option value="综合部">综合部</option>
					  <option value="其他部门">其他部门</option>
					</select>
					<div class="srkbt">姓名</div>
					<input class="sws" type="text" v-model="fromdata.xm"/>
					<div class="srkbt">手机号</div>
					<input class="sws"  type="text" v-model="fromdata.sjh"/>
					<div class="srkbt" type="text">联系时间</div>
					<div class="dxk">
						<div class="gzsj" :class="{ xz:gzsj }" @click="lxsj(1)">
							<div class="yd" :class="{ xzyd:gzsj }"></div>
							工作时间
						</div>
						<div class="gzsj" :class="{ xz:fgzsj }" @click="lxsj(2)" style="margin-right: 0;">
							<div class="yd" :class="{ xzyd:fgzsj }"></div>
							任意时间
						</div>
					</div>
					<div class="srkbt" type="text">您的问题</div>
					<div class="dxk">
						<div class="gzsj" :class="{ xz:azwt }" @click="ndwt(1)">
							<div class="yd" :class="{ xzyd:azwt }"></div>
							安装问题
						</div>
						<div class="gzsj" :class="{ xz:xszx }" @click="ndwt(2)">
							<div class="yd" :class="{ xzyd:xszx }"></div>
							销售咨询
						</div>
					</div>
					<div class="xljdcpxxk" v-if="fromdata.wt == '安装问题'">
						<div class="xyljdcpxx" @click="bddxff(7)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.ncbg }"></div>
							内存不够
						</div>
						<div class="xyljdcpxx" @click="bddxff(8)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.azbl }"></div>
							安装不上
						</div>
						<div class="xyljdcpxx" @click="bddxff(9)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.sjbl }"></div>
							升级不了
						</div>
						<div class="xyljdcpxx" @click="bddxff(10)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.zcbl }"></div>
							注册不了
						</div>
					</div>
					<div class="xljdcpxxk" v-if="fromdata.wt == '销售咨询'">
						<div class="xyljdcpxx" @click="bddxff(2)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.ssfzxx }"></div>
							网络表格及文档平台
						</div>
						<div class="xyljdcpxx" @click="bddxff(3)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.hzzxxx }"></div>
							会计师事务所管理系统
						</div>
						<div class="xyljdcpxx" @click="bddxff(4)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.dzdgxx }"></div>
							会计师事务所函证中心
						</div>
						<div class="xyljdcpxx" @click="bddxff(5)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.czcqgj }"></div>
							取数查账抽凭工具
						</div>
						<div class="xyljdcpxx" @click="bddxff(6)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.bgtxgj }"></div>
							报告赋码自动填写工具
						</div>
					</div>
					<div class="tj" v-if="tjqny == 0" @click="lxwm()">提交</div>
					<div  class="tj1" v-if="tjqny == 1">{{tjants}}</div>
					<div class="btts" :class="{ bttsbxs: bttsxs }">{{btss}}</div>
				</div>
			</div>
		</div>
		<div class="foot">
			<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021002230号-1</a><br>
			北京独木成林科技有限公司<br>
			Beijing One Tree Forest Technology Co., Ltd.
		</div>
	</div>
</template>

<style scoped>
.gzdytp1{
	display: block;
	width: 80%;
	margin: 0 auto;
}
.dxyd{
	width: 28px;
	height: 28px;
	border-radius: 14px;
	border: 1px solid #68747f;
	position: absolute;
	left: 30px;
	top: 16px;
	box-sizing: border-box;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.dxydxz{
	border: 10px solid #05ac73;
}
.xyljdcpxx{
	width: 100%;
	height: 60px;
	background: #f8fafc;
	margin-bottom: 20px;
	font-size: 21px;
	color: #616076;
	display: flex;
	justify-content: left;
	align-items: center;
	box-sizing: border-box;
	padding-left: 80px;
	position: relative;
}
.xljdcpxxk{
	width: 100%;
	margin-top: 20px;
}
.wlhnrdy{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 55px;
	line-height: 40px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.wlhnrdy1{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 55px;
	line-height: 40px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.wlhbtdy{
	font-size: 28px;
	line-height: 45px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.gzdy{
	display: block;
	width: 400px;
	margin: 0 auto;
	margin-top: 50px;
	box-shadow: 0px 0px 43px 0px rgba(6, 0, 1, 0.1);
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.page8{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	overflow: hidden;
	padding-bottom: 70px;
	padding-top: 70px;
}
.foot{
	width: 100%;
	background: #f5f9fa;
	margin-top: 50px;
	color: #68747f;
	line-height: 30px;
	text-align: center;
	font-size: 18px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.foot a{
	color: #68747f!important;
}
.tj{
	height: 60px;
	width: 100%;
	background: #05ac73;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 21px;
	color: #FFFFFF;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.yd{
	width: 21px;
	height: 21px;
	border-radius: 10.5px;
	border: 1px solid #68747f;
	position: absolute;
	left: 15px;
	top: 18px;
	box-sizing: border-box;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.gzsj{
	display: inline-block;
	height: 100%;
	box-sizing: border-box;
	padding-left: 50px;
	padding-right: 15px;
	position: relative;
	color: #68747f;
	font-size: 21px;
	line-height: 30px;
	text-align: center;
	border: 1px solid #e0e0e0;
	float: left;
	line-height: 58px;
	border-radius: 5px;
	margin-right: 25px;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.xz{
	border-color: #05ac73;
	color: #05ac73;
}
.xzyd{
	border: 8px solid #05ac73;
}
.dxk{
	height: 60px;
	width: 100%;
	margin-top: 20px;
}
.sws .xlysx{
	width: 429px;
}
.sws:focus{
	outline:none;
	border: 1px solid #05ac73;
}
.sws{
	width: 100%;
	height: 60px;
	background: #FFFFFF;
	border: 1px solid #e0e0e0;
	font-size: 21px;
	color: #616076;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
	line-height: 58px;
	margin-top: 20px;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.srkbt{
	width: 100%;
	font-size: 23px;
	color: #616076;
	text-align: left;
	margin-top: 30px;
}
.lxwmbt{
	width: 100%;
	font-size: 25px;
	color: #68747f;
	text-align: center;
	line-height: 40px;
}
.lxwmsrk{
	width: 454px;
	background: #FFFFFF;
	background-image: url(../../assets/img/lxwmbg.png);
	background-repeat: no-repeat;
	background-size: 454px 103px;
	background-position: left 20px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	margin-top: -82px;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	padding-top: 50px;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 50px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.7s;
	-webkit-animation-delay: 0.7s;
	-o-animation-delay: 0.7s;
	animation-delay: 0.7s;
}
.lxwm{
	width: 100%;
	display: block;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.lxwmk{
	width: 100%;
}
.page7{
	width: 100%;
	margin-bottom: 50px;
}
.jrxzk{
	width: 315px;
	height: 350px;
	margin: 0 auto;
	margin-top: 65px;
	position: relative;
}
.jrxz{
	width: 274px;
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -137px;
	z-index: 1;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.jrg{
	width: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1s;
	-webkit-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;
}
.excel3{
	width: 67px;
	display: block;
	position: absolute;
	bottom: 147px;
	left: 123px;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
	-o-animation-delay: 2.5s;
	animation-delay: 2.5s;
}
.excel2{
	width: 74px;
	display: block;
	position: absolute;
	bottom: 203px;
	right: 55px;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 2s;
	-webkit-animation-delay: 2s;
	-o-animation-delay: 2s;
	animation-delay: 2s;
}
.excel1{
	width: 74px;
	display: block;
	position: absolute;
	bottom: 220px;
	left: 69px;
	z-index: 2;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1.5s;
	-webkit-animation-delay: 1.5s;
	-o-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.bf{
	display: block;
	height: 90px;
	position: absolute;
	top: 157.5px;
	left: 50%;
	margin-left: -45px;
}
.lxwman{
	width: 106px;
	height: 44px;
	background: #60cda4;
	border-radius: 6px;
	font-size: 20px;
	color: #FFFFFF;
	text-align: center;
	position: absolute;
	right: 23px;
	top: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.logo{
	position: absolute;
	display: block;
	height: 66px;
	left: 23px;
	top: 17px;
}
.hade{
	width: 100%;
	height: 100px;
	box-sizing: border-box;
	background: #FFFFFF;
	border-bottom: 1px solid #f5f5f5;
	position: relative;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
}
.wlhnr3{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 55px;
	line-height: 40px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.wlhbt3{
	font-size: 30px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
	margin-top: 90px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.page6{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	overflow: hidden;
	background: #f5f9fa;
	padding-bottom: 60px;
	padding-top: 60px;
}
.sjsj{
	display: block;
	width: 85px;
	position: absolute;
	bottom: 75px;
	left: 0;
}
.sjdn{
	display: block;
	width: 100%;
	position: absolute;
	top: 15px;
	left: 0;
}
.zdsjtpk{
	width: 100%;
	margin-top: 50px;
	height: 338px;
	position: relative;
}
.wlhbt2{
	font-size: 28px;
	color: #68747f;
	text-align: center;
	margin-top: 30px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.page5{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	overflow: hidden;
	padding-bottom: 30px;
}
.page4{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	padding-top: 60px;
	padding-bottom: 60px;
	overflow: hidden;
	background: #f5f9fa;
}
.pag3{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
}
.sdgnxkmg{
	margin-bottom: 50px;
}
.sdgnxk{
	width: 100%;
	background: rgb(255,255,255,1);
	position: relative;
	padding-top: 40px;
	padding-bottom: 50px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.dzdg{
	height: 100%;
	display: block;
	margin:0 auto;
}
.dzdgtpk{
	margin-top: 40px;
	width: 100%;
	height: 569px;
}
.hzzx{
	height: 100%;
	display: block;
	margin:0 auto;
}
.hzzxtpk{
	margin-top: 40px;
	width: 100%;
	height: 364px;
}
.ssfz{
	width: 410px;
	display: block;
	margin:0 auto;
}
.wlhbt1{
	font-size: 30px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
	margin-top: 90px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.czxxts{
	height: 117px;
	display: block;
	position: absolute;
	top: 0;
	right: 10px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.7s;
	-webkit-animation-delay: 0.7s;
	-o-animation-delay: 0.7s;
	animation-delay: 0.7s;
}
.yskf{
	width: 438px;
	display: block;
	position: absolute;
	top: 0;
	left: 16px;
}
.yskfk{
	width: 100%;
	height: 340px;
	margin-top: 60px;
	position: relative;
	overflow: hidden;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
	-o-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.wlhnr{
	font-size: 23px;
	color: #68747f;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	line-height: 40px;
}
.wlhbt{
	font-size: 30px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
	line-height: 55px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.xlts{
	position: absolute;
	display: block;
	width: 394px;
	bottom: 0;
	left: 50%;
	margin-left: -197px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	-o-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.dzdg1{
	position: absolute;
	display: block;
	width: 198px;
	top: 72px;
	right: 31px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 2s;
	-webkit-animation-delay: 2s;
	-o-animation-delay: 2s;
	animation-delay: 2s;
}
.hzzx1{
	position: absolute;
	display: block;
	width: 198px;
	top: 72px;
	left: 31px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1.5s;
	-webkit-animation-delay: 1.5s;
	-o-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.fzss1{
	position: absolute;
	display: block;
	width: 198px;
	top: 0;
	left: 50%;
	margin-left: -99px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 1s;
	-webkit-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;
}
.syzzk{
	width: 100%;
	height: 555px;
	margin-top: 20px;
	position: relative;
}
.sdgn{
	width: 410px;
	display: block;
	margin: 0 auto;
	margin-top: 30px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.sdgnbt{
	font-size: 29px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
	margin-top: 90px;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.page{
	width: 100%;
	overflow: hidden;
}
.banner{
	width: 100%;
	height: 405px;
	background-image: url(../../assets/img/banner.png);
	background-repeat: no-repeat;
	background-position: 0px 0px;
	background-size: 100% 100%;
	position: relative;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-o-animation-delay: 0s;
	animation-delay: 0s;
}
.page2{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	padding-bottom: 60px;
	padding-top: 60px;
	background: #f5f9fa;
}
.page1{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	padding-bottom: 90px;
}
.TNP{
	width: 318px;
	height: 423px;
	margin: 0 auto;
	margin-top: 35px;
	margin-bottom: 90px;
	position: relative;
}
.tnpdz{
	width: 100%;
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 1;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.tnpg1{
	width: 226px;
	display: block;
	position: absolute;
	left: 50%;
	margin-left:-113px;
	top: 0;
	z-index: 4;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.tnpg3{
	width: 100%;
	display: block;
	position: absolute;
	left: 0;
	bottom: 85px;
	z-index: 5;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.tnpg2{
	width: 65px;
	display: block;
	position: absolute;
	left: 50%;
	margin-left:-32.5px;
	bottom: 173px;
	z-index: 3;
	-moz-animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	-o-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
@keyframes logodh{0%{ bottom:227px;}50%{ bottom:260px;}100%{ bottom:227px;}}
@-moz-keyframes logodh{0%{ bottom:227px;}50%{ bottom:260px;}100%{ bottom:227px;}}
@-webkit-keyframes logodh{0%{ bottom:227px;}50%{ bottom:260px;}100%{ bottom:227px;}}
@-o-keyframes logodh{0%{ bottom:227px;}50%{ bottom:260px;}100%{ bottom:227px;}}
.tnplogo{
	width: 91px;
	display: block;
	position: absolute;
	left: 50%;
	margin-left:-45.5px;
	bottom: 227px;
	z-index: 2;
	animation:logodh 2s infinite linear;
	-moz-animation:logodh 2s infinite linear;
	-webkit-animation:logodh 2s infinite linear;
	-o-animation:logodh 2s infinite linear;
}
.tnpbt{
	font-size: 45px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
	margin-top: 50px;
}
.tnpmx{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	font-weight: bold;
	margin-top: 25px;
	margin-bottom: 110px;
}
.videomasks{
	position: fixed;
	left: 0;
	top: 0;
	background: rgb(0,0,0,0.5);
	width: 100%;
	height: 100%;
	z-index: 9997;
}
.xcspbfq{
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9998;
}
.gbsp{
	position: absolute;
	width: 60px;
	top: 60px;
	z-index: 9999;
	right: 43px;
	top: 43px;
}
.lxwmbtzd{
	color: #F56C6C;
}
.btts{
	width: 100%;
	font-size: 23px;
	color: #F56C6C;
	text-align: center;
	margin-top: 40px;
}
.bttsbxs{
	display: none;
}
.tj1{
	height: 60px;
	width: 100%;
	background: #EBEEF5;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 21px;
	color: #909399;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.animate__animated{
	opacity: 0;
}
</style>

<script>
import wxapi from './wxapi.js';
export default {
	name: 'pc',
	data () {
		return {
			azwt: false,
			xszx: false,
			videoState:false,
			isShowSelect: false,
			gzsj: true,
			fgzsj: false,
			wh: 0,
			btss:'请输入姓名',
			bttsxs:true,
			tjqny:0,
			tjants:'提交中',
			bddxxx:{
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false
			},
			azwtlb:{
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			},
			fromdata:{
			  sws: '',
			  zw:'',
			  xm: '',
			  sjh: '',
			  sj: '工作时间',
			  lj:'',
			  wt:''
			},
			ly:'yd',
			tjr:'',
			jgywc:false,
			ssfzbsk:false,
			hzzxbsk:false,
			dzdgsk:false,
			tnpbb:false,
			qxlss:false,
			dz:false,
			gs:false,
			gy:false,
			ge:false,
			qmjrbt:false,
			qmjrexcelxz:false,
			qmjrexcelg:false,
			qmjrexcel1:false,
			qmjrexcel2:false,
			qmjrexcel3:false,
			wlhsjbt:false,
			wlhsjnr:false,
			sjdndh:false,
			sjsjdh:false,
			zcappbt:false,
			sjjmzsts:false,
			sjjmzstp:false,
			lxwmtp:false,
			lxwmsrkdh:false,
			gzdybt:false,
			gzdyms1:false,
			gzdytp1:false
		}
	},
	mounted () {
		let that = this;
		if(that.$route.params.id){
			that.tjr = that.$route.params.id;
		}
	 //    window.addEventListener('scroll', this.handleScroll);
		// this.wh = window.innerHeight - 100;
		// this.handleScroll();
		let url = encodeURIComponent(location.href.split('#')[0]);
		let dz = window.location.href;
		this.$http('/wxjsapi?url=' + url).then(function (response) {
			let data = response.data;
			let option = {
				title: "榕树万千李金鹏",
				desc: "会计师事务所数字化专家",
				link: dz,
				imgUrl: "https://www.dmclkj.com/slt.png",
			};
			wxapi.wxRegister(data,option);
		}).catch(function (error) {
			
		});
		let broser = window.navigator.userAgent.toLowerCase();
		if(broser.match(/MicroMessenger/i) == 'micromessenger'){
		     that.ly = "wx";
		}
		this.$http('/browse?source=' + that.ly + '&tjr=' + that.tjr).then(function (response) {
			
		}).catch(function (error) {
			
		});
	},
	// beforeDestroy() {
	// 	window.removeEventListener("scroll",this.handleScroll)
	// },
	methods: {
		// handleScroll() {
		// 	this.currentScroll = window.pageYOffset;
		// 	if ((this.currentScroll + this.wh) >= this.$refs.jgywc.offsetTop) {
		// 		this.jgywc = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.ssfzbsk.offsetTop) {
		// 		this.ssfzbsk = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.hzzxbsk.offsetTop) {
		// 		this.hzzxbsk = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.dzdgsk.offsetTop) {
		// 		this.dzdgsk = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.tnpbb.offsetTop) {
		// 		this.tnpbb = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.qxlss.offsetTop) {
		// 		this.qxlss = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.dz.offsetTop + this.$refs.tnptprq.offsetTop)) {
		// 		this.dz = true;
		// 	}
		// 	if ((this.currentScroll + this.wh - 50) >= (this.$refs.gy.offsetTop + this.$refs.tnptprq.offsetTop)) {
		// 		this.gy = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.gs.offsetTop + this.$refs.tnptprq.offsetTop)) {
		// 		this.gs = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.ge.offsetTop + this.$refs.tnptprq.offsetTop)) {
		// 		this.ge = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.qmjrbt.offsetTop + this.$refs.qmjrnrk.offsetTop)) {
		// 		this.qmjrbt = true;
		// 	}
		// 	if ((this.currentScroll + this.wh - 100) >= (this.$refs.qmjrexcelxz.offsetTop + this.$refs.qmjrnrk.offsetTop)) {
		// 		this.qmjrexcelxz = true;
		// 	}
		// 	if ((this.currentScroll + this.wh - 150) >= (this.$refs.qmjrexcelg.offsetTop + this.$refs.qmjrnrk.offsetTop)) {
		// 		this.qmjrexcelg = true;
		// 	}
		// 	if ((this.currentScroll + this.wh - 100) >= (this.$refs.qmjrexcel1.offsetTop + this.$refs.qmjrnrk.offsetTop)) {
		// 		this.qmjrexcel1 = true;
		// 	}
		// 	if ((this.currentScroll + this.wh - 100) >= (this.$refs.qmjrexcel2.offsetTop + this.$refs.qmjrnrk.offsetTop)) {
		// 		this.qmjrexcel2 = true;
		// 	}
		// 	if ((this.currentScroll + this.wh - 100) >= (this.$refs.qmjrexcel3.offsetTop + this.$refs.qmjrnrk.offsetTop)) {
		// 		this.qmjrexcel3 = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.wlhsjbt.offsetTop) {
		// 		this.wlhsjbt = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.wlhsjnr.offsetTop) {
		// 		this.wlhsjnr = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.sjdndh.offsetTop + this.$refs.sjdhk.offsetTop)) {
		// 		this.sjdndh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.sjsjdh.offsetTop + this.$refs.sjdhk.offsetTop)) {
		// 		this.sjsjdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.zcappbt.offsetTop + this.$refs.sjappk.offsetTop)) {
		// 		this.zcappbt = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.sjjmzsts.offsetTop + this.$refs.sjappk.offsetTop)) {
		// 		this.sjjmzsts = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= (this.$refs.sjjmzstp.offsetTop + this.$refs.sjappk.offsetTop)) {
		// 		this.sjjmzstp = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.lxwmtp.offsetTop) {
		// 		this.lxwmtp = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.lxwmsrkdh.offsetTop) {
		// 		this.lxwmsrkdh = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.gzdybt.offsetTop) {
		// 		this.gzdybt = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.gzdyms1.offsetTop) {
		// 		this.gzdyms1 = true;
		// 	}
		// 	if ((this.currentScroll + this.wh) >= this.$refs.gzdytp1.offsetTop) {
		// 		this.gzdytp1 = true;
		// 	}
		// },
		golxwm(){
		   document.querySelector("#lxwm").scrollIntoView(true);
		},
		lxsj(lx){
			let that = this;
			if(lx == 1){
				that.gzsj = true;
				that.fgzsj = false;
				that.fromdata.sj = "工作时间";
			}else{
				let that = this;
				that.gzsj = false;
				that.fgzsj = true;
				that.fromdata.sj = "任意时间";
			}
		},
		ndwt(lx){
			let that = this;
			if(lx == 1){
				that.azwt = true;
				that.xszx = false;
				that.fromdata.wt = "安装问题";
			}else{
				that.azwt = false;
				that.xszx = true;
				that.fromdata.wt = "销售咨询";
			}
			that.bddxxx = {
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false,
				bgtxgj:false
			}
			that.azwtlb = {
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			}
		},
		bddxff(xx){
			let that = this;
			if(xx == 2){
				if(that.bddxxx.ssfzxx == false){
					that.bddxxx.ssfzxx = true;
				}else{
					that.bddxxx.qbxx = false;
					that.bddxxx.ssfzxx = false;
				}
			}
			if(xx == 3){
				if(that.bddxxx.hzzxxx == false){
					that.bddxxx.hzzxxx = true;
				}else{
					that.bddxxx.qbxx = false;
					that.bddxxx.hzzxxx = false;
				}
			}
			if(xx == 4){
				if(that.bddxxx.dzdgxx == false){
					that.bddxxx.dzdgxx = true;
				}else{
					that.bddxxx.dzdgxx = false;
				}
			}
			if(xx == 5){
				if(that.bddxxx.czcqgj == false){
					that.bddxxx.czcqgj = true;
				}else{
					that.bddxxx.czcqgj = false;
				}
			}
			if(xx == 6){
				if(that.bddxxx.bgtxgj == false){
					that.bddxxx.bgtxgj = true;
				}else{
					that.bddxxx.bgtxgj = false;
				}
			}
			if(xx == 7){
				if(that.azwtlb.ncbg == false){
					that.azwtlb.ncbg = true;
				}else{
					that.azwtlb.ncbg = false;
				}
			}
			if(xx == 8){
				if(that.azwtlb.azbl == false){
					that.azwtlb.azbl = true;
				}else{
					that.azwtlb.azbl = false;
				}
			}
			if(xx == 9){
				if(that.azwtlb.sjbl == false){
					that.azwtlb.sjbl = true;
				}else{
					that.azwtlb.sjbl = false;
				}
			}
			if(xx == 10){
				if(that.azwtlb.zcbl == false){
					that.azwtlb.zcbl = true;
				}else{
					that.azwtlb.zcbl = false;
				}
			}
		},
		checkVideoFun(){
			this.videoState = true;
		},
		masksCloseFun(){
			this.videoState = false;
		},
		lxwm(){
			let that = this;
			that.fromdata.lj = '';
			if(that.fromdata.wt == '安装问题'){
				if(that.azwtlb.ncbg == true){
					that.fromdata.lj = that.fromdata.lj + '内存不够';
				}
				if(that.azwtlb.azbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '安装不上';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，安装不上';
					}
				}
				if(that.azwtlb.sjbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '升级不了';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，升级不了';
					}
				}
				if(that.azwtlb.zcbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '注册不了';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，注册不了';
					}
				}
			}else if(that.fromdata.wt == '销售咨询'){
				if(that.bddxxx.ssfzxx == true){
					that.fromdata.lj = that.fromdata.lj + '网络表格及文档平台';
				}
				if(that.bddxxx.hzzxxx == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '会计师事务所管理系统';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，会计师事务所管理系统';
					}
				}
				if(that.bddxxx.dzdgxx == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '会计师事务所函证中心';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，会计师事务所函证中心';
					}
				}
				if(that.bddxxx.czcqgj == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '取数查账抽凭工具';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，取数查账抽凭工具';
					}
				}
				if(that.bddxxx.bgtxgj == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '报告赋码自动填写工具';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，报告赋码自动填写工具';
					}
				}
			}
			if(that.fromdata.lj==''){
				that.btss = '请选择您的问题！';
				that.bttsxs = false;
			}else if(that.fromdata.sws == '' || that.fromdata.zw == '' || that.fromdata.sjh == '' || that.fromdata.xm == '' || that.fromdata.wt == ''){
				that.btss = '请填写完整信息！';
				that.bttsxs = false;
			}else if(!(/^1[23456789]\d{9}$/.test(this.fromdata.sjh))){
				that.btss = '请确认手机号是否正确！';
				that.bttsxs = false;
			}else{
				that.btss = '';
				that.bttsxs = true;
				that.tjqny = 1;
				that.tjants = '提交中';
				this.$http.post('/contact', {
					wt: that.fromdata.wt,
					sws: that.fromdata.sws,
					zw:that.fromdata.zw,
					xm: that.fromdata.xm,
					sjh:that.fromdata.sjh,
					sj:that.fromdata.sj,
					lj:that.fromdata.lj,
					ly:that.ly,
					tjr:that.tjr
				  })
				  .then(function (response) {
					  if(response.data.errmsg=='ok'){
						  alert('提交成功！')
						  that.tjants = '提交成功';
						  that.fromdata.sws = '';
						  that.fromdata.zw = '';
						  that.fromdata.xm = '';
						  that.fromdata.sjh = '';
						  that.fromdata.lj = '';
					  }else{
						  alert('网络异常，请再次提交！')
						  that.btss = '';
						  that.tjqny = 0;
						  that.bttsxs = false;
					  }
				  }).catch(function (error) {
				    alert('网络异常，请再次提交！')
					that.btss = '';
					that.tjqny = 0;
					that.bttsxs = false;
				  });
			}
		}
	}
};
</script>
